<template>
  <div class="physical-copy-logo">
    <div class="item"><span class="copy-text">Physical Record Only</span></div>
    <div class="item"><img :src="$getAsset('/assets/mcr-logo-grey-light.png')" width="150" /></div>
    <div v-if="zupu.provider" v-prerender-if="'provider.name'" class="item text-item">
      <span v-if="zupu.provider.name">{{ zupu.provider.name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    zupu: Object,
  },
  name: 'PhysicalCopyLogo',
};
</script>

<style lang="scss" scoped>
.physical-copy-logo {
  display: flex;
  flex-flow: column;
  align-items: center;

  background-color: $background-label;
  padding: 50px;
  .item {
    margin-bottom: 20px;
  }

  .text-item {
    max-width: 350px;
    margin-bottom: 0;
  }
  .copy-text {
    color: $supplemental-text-color;
  }
}
</style>
