export default {
  ancestral_places: [
    {
      full_address_ch: '{{ ancestral_places.0.full_address_ch }}',
      full_address_en: '{{ ancestral_places.0.full_address_en }}',
      name: '{{ ancestral_places.0.name }}',
      object_id: '{{ ancestral_places.0.object_id }}',
      pinyin: '{{ ancestral_places.0.pinyin }}',
    },
  ],
  author: '{{ author }}',
  call_number: '{{ call_number }}',
  clan_hall_name: '{{ clan_hall_name }}',
  clans: [
    {
      name_ch: '{{ clans.0.name_ch }}',
      name_hant: '{{ clans.0.name_hant }}',
      object_id: '{{ clans.0.object_id }}',
      pinyin: '{{ clans.0.pinyin }}',
    },
  ],
  cover_id: '{{ cover_id }}',
  cover_url: '{{ cover_url }}',
  free_preview_images_count: 5,
  full_title: "{{ full_title|default_if_none:'' }}",
  has_images: '{{ has_images }}',
  has_ocr: '{{ has_ocr }}',
  images_count: '{{ images_count }}',
  is_physical_only: '{{ is_physical_only }}',
  is_publicly_available: '{{ is_publicly_available }}',
  is_user_saved: false,
  mentions_count: '{{ mentions_count }}',
  object_id: '{{ object_id }}',
  previewed_images_count: 0,
  provider: {
    name: '{{ provider.name }}',
    url: '{{ provider.url }}',
    contact_info: '{{provider.contact_info}}',
    type: '{{provider.type}}',
    parent: '{{provider.parent}}',
  },
  publication_place: {
    id: '{{ publication_place.id }}',
    is_migration_destination: '{{ publication_place.is_migration_destination }}',
    pinyin: '{{ publication_place.pinyin }}',
  },
  publication_year: '{{ publication_year }}',
  publication_year_latest: '{{ publication_year_latest }}',
  resource_uri: '{{ resource_uri }}',
  source: '{{ source }}',
  source_object_id: '{{ source_object_id }}',
  summary: '{{ summary }}',
  summary_preview: '{{ summary_preview }}',
  title_original: '{{ title_original }}',
  title_pinyin: '{{ title_pinyin }}',
  user_has_full_access: '{{ user_has_full_access }}',
  volume_count: '{{ volume_count }}',
};
